<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 d-flex">
        <div class="wrapper w-100 m-auto pb-5">
          <div class="text-center">
            <button v-if="tipo === 'fisico'" class="btn btn-primary mx-1 mb-5 px-4 py-2 rounded-pill" type="button" disabled="true">
              Pessoa física
            </button>
            <button v-if="tipo === 'juridico'" class="btn btn-primary mx-1 mb-5 px-4 py-2 rounded-pill" type="button" disabled="true">
              Pessoa Jurídica
            </button>
          </div>

          <FormAtorFisico v-if="tipo === 'fisico'" :registry="registry" :readOnlyMode="true" />
          <FormAtorJuridico v-if="tipo === 'juridico'" :registry="registry" :readOnlyMode="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormAtorFisico from './FormAtorFisico.vue';
import FormAtorJuridico from './FormAtorJuridico.vue';
import AtoresRepository from '../../libs/repositories/AtoresRepository.js';

export default {
  name: 'AtoresVisualizacao',
  data () {
    return {
      registry: null,
      tipo: null
    };
  },
  components: {
    FormAtorFisico,
    FormAtorJuridico
  },
  async created () {
    const id = +this.$route.params.id;

    const registry = await AtoresRepository.getById(id);

    if (registry) {
      this.tipo = registry.tipo_do_ator;
      this.registry = registry;
    }
  }
};

</script>
